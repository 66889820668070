import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { FaArrowRight } from "react-icons/fa";
import example from "../../assets/image/jpeg/examples.jpg";
 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { device, breakpoints } from "../../utils";
import Newarrow from '../../assets/image/jpeg/enter.svg';



const Bookbutton = styled.button`
position: relative;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
height: 100%;
padding: 0.8em 2.4em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
font-family: azo-sans-web, sans-serif;
color: #fff;
font-size: 0.7em;
letter-spacing: 2px;
text-decoration: none;
text-transform: uppercase;
cursor: pointer;
border:none;
background-color: #00afc9;
color: #fff;
opacity: 0.8;
transition: 0.3s;

&:hover{
  opacity: 1
}
`;





const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;

const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
 

}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 2em;
background-color: #999;

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: #999;
font-size: 1em;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 16px;
}

`;



const Secondtext = styled.div`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 3.2em;

      margin-bottom: 0.4em;
 
      color: #111;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 26px;
   
      }

`;


const Thirdtext = styled.div`

opacity: 1;
transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
0deg) rotateY(
0deg) rotateZ(
0deg) skew(
0deg, 
0deg);
transform-style: preserve-3d;
font-family: halyard-display, sans-serif;

width: 90%;
color: rgba(255, 255, 255, 0.7);


color: #fff;
font-size: 1.6em;
line-height: 1.5;


@media screen and (max-width: 767px)
{
     margin-bottom: 24px;
     font-size: 22px;
     text-align:center;
     width: 100%;
 }

`;
const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;

const Buttonnew = styled.button `
color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efe8de;
  border: none;
  padding: 1rem 4rem;
  font-weight: 600;
  font-family: termina, sans-serif;
  text-transform: uppercase;
  font-size: 0.7rem;
  position: relative;
  border-radius: 100px;
  z-index: 1;
  margin-top:20px;
  margin-right: 1rem;
  cursor: pointer;
  outline: none;
  transform-style: preserve-3d;
  transition: 0.2s ease-out;

  &:hover {
    &.main span {
      color: #fff;
      mix-blend-mode: difference;
    }
    &.alt span {
      mix-blend-mode: difference;
      color: white;
    }
  }

  &.main {
    background: #00afc9;
    color: #efe8de;
    margin: 0 auto;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: -5px -6px 10px #efe8de;
    filter: brightness(1.2);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: 5px 10px 15px #efe8de;
    filter: brightness(0.8);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &:active {
    &.alt {
      &::after {
        box-shadow: inset 4px 4px 8px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #efe8de;
      }
    }
    &.main {
      &::after {
        box-shadow: 2px 5px 6px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #222;
      }
    }
  }
`

export default function LiquidnonFillfaq() {
    return (
        <Section py="4" id="faq" bg="#fff" className="pb-md-5 mb pt-md-5">
      <Container className="pb-md-5 mb pt-md-5">
        <Row className="justify-content-center">
          <Col lg="12">
   
            <Iwrap>


<Itext as="h2">More about Nose Filler</Itext>
</Iwrap>
      <Secondtext>
      Frequently Asked Questions

      </Secondtext>
         
          </Col>
        </Row>


        <Row className="">
        <Col
     
     >
          
         


           <Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        What does non-surgical rhinoplasty entail in London?      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Non-surgical rhinoplasty, also known as "the 10-minute nose job," is a procedure that involves injecting HA-based dermal fillers into the nose to temporarily alter its form. <br /> <br />

Dermal filler is strategically injected slowly and precisely in little amounts into precise areas of the nose using a very fine needle. The hyaluronic acid filler enhances the area where it's injected, quickly changing the shape of the nose. The patient will be awake and under no general anaesthesia, allowing the patient to observe the effects as the injection is delivered.





        </p>
    </AccordionItemPanel>
</AccordionItem>




</Accordion>  

<br />


      
<Accordion allowZeroExpanded className="shadow">

<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        How long does it take to have a non-surgical rhinoplasty? 
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Nonsurgical rhinoplasty takes less than 15 minutes, and patients will witness instant results with minimal downtime. You can return to your usual activities or work the same day. 

        </p>
    </AccordionItemPanel>
</AccordionItem>



</Accordion>  


<br />


      
<Accordion allowZeroExpanded className="shadow">

<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Is non-surgical rhinoplasty painful? 
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Before beginning the treatment, we will apply numbing lotion to the nose (optional) to make your experience as painless as possible. We don't start the treatment until the numbing cream has fully taken effect. According to our prior patients, many do not experience pain due to the effect of the numbing cream, while others experience minimum discomfort.
        </p>
    </AccordionItemPanel>
</AccordionItem>



</Accordion>    
         
<br />
        
<Accordion allowZeroExpanded className="shadow">
        <AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        How long does it take to recover? Will the Non-Surgical Rhinoplasty result in any bruising or marks?       </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        There is no downtime after a non-surgical rhinoplasty; you can return to your daily activities or job the very same day if they do not contain any physical activity or sun exposure. <br /> <br />

Following the treatment, you will notice immediate results, with ultimate results taking two weeks to allow any mild swelling to subside and the nose to completely heal. As with any injectable-related adverse effects, redness at the injected site is typical and expected.<br /> <br />

The treatment is unlikely to leave any visible marks, but redness is expected. During the appointment prior to the treatment, more information about what to expect during and after the treatment will be thoroughly explained.



      </p>
    </AccordionItemPanel>
</AccordionItem>





</Accordion>    
<br />
<Accordion allowZeroExpanded className="shadow">
 
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Is Non-Surgical Rhinoplasty a Safe Procedure?   </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Rhinoplasty is a non-surgical procedure that involves injecting hyaluronic acid gel into the soft tissue of the nose to shape, contour, and straighten it. Dermal fillers based on hyaluronic acid are made from natural sources rather than from animals. <br /><br />

The fillers dissolve naturally in the body over time as the body is able to break them down, and the treatment results fade over time. The filler, like naturally occurring hyaluronic acid in the skin, breaks down and is reabsorbed by the body. Hyaluronic acid has a reputation for being a safe, effective, and a good alternative to surgery.<br /><br />









        </p>
    </AccordionItemPanel>
</AccordionItem>




</Accordion>    
<br /> 


        

<Accordion allowZeroExpanded className="shadow">
  
          <AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        12-18 months after my non-surgical rhinoplasty, what happens? 
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Patients gain confidence after undergoing nonsurgical rhinoplasty because they witness immediate and natural-looking improvements. Patients will notice a change in their appearance shortly after that. One of the most appealing features of nonsurgical rhinoplasty is that it is not a long-term solution, making it suitable for people who want to experiment with different looks without committing to surgery or entirely changing their face. The nose can be tweaked as your face changes over time to keep the result. <br /> <br />
The treatment's duration is determined by a number of factors, including the patient's metabolic rate, lifestyle factors, and nasal anatomy. As the face evolves over time, tweaks can be made to keep the result consistent. After 10-12 months, the majority of patients return for a follow-up treatment. Some patients return after two years since the contour often holds up well, requiring less filler at this point.


        </p> 
    </AccordionItemPanel>
</AccordionItem>

</Accordion>

<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Is it true that non-surgical rhinoplasty can make your nose appear smaller? 
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        From the front and side profile, strategically injecting in the right locations of the nose can provide the idea of it being smaller. Fillers can be used to plump out spots on the nose to change its form, as well as change the angle of the nose and the way it reflects light. This  is effective for making modest changes and tweaks to the nose rather than making significant changes such as physically reducing the nose's shape.

        </p>
    </AccordionItemPanel>
</AccordionItem>


</Accordion>  



 <br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        How much does non-surgical rhinoplasty cost in the United Kingdom? 
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        <b>The cost of a Non-Surgical Rhinoplasty at Dermamina London is fixed at £450. The patient is not restricted in terms of filler quantity; rather, the  focuses on achieving a complete, satisfying result with no restrictions on filler quantity. </b> 

        </p>
    </AccordionItemPanel>
</AccordionItem>


</Accordion>  


<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Is non-surgical rhinoplasty a permanent procedure? 
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        One of the most appealing features of nonsurgical rhinoplasty is that it is not a permanent solution, making it suitable for those who wish to experiment with different looks without committing to surgery or entirely changing their face. Regular dermal filler treatments to plump up and revitalise the proper parts of the face can help you retain your look as your face changes over time.

        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>  


<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        What should I do to be ready for a non-surgical rhinoplasty? 
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        It's best to arrive for your consultation with your skin in the finest condition possible. This includes wearing less makeup on your face, getting plenty of rest the night before so you can be calm and comfortable during the treatment, staying hydrated, and eating sufficient. Please arrive on time, complete all online paperwork, and have reasonable expectations. Dermal fillers aren't meant to entirely transform your appearance; rather, they're meant to enhance it. <br /><br />

As soon as you make a reservation, you will receive pre-treatment instructions, which will be included in your form. The following is a list of our pre-care recommendations: <br /><br />

- For one week before your appointment, don't take any blood-thinning drugs like aspirin or ibuprofen. This is done to avoid bruising during and after the procedure. Before discontinuing any medicine, please speak with your doctor. <br /><br />

- Do not schedule an appointment if you have recently had or plan to have substantial dental work done. Root canal extraction and , as well as deep filing, are included. It will be alright within two weeks. <br /><br />

- Arnica tablets can be administered before and after treatment to help prevent bruising (optional)<br /><br />

- Avoid drinking alcohol for 2-3 days before your visit to reduce the chance of bleeding and bruising.





        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>  


<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        What should I avoid following a non-surgical rhinoplasty procedure? 
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
    It is critical that all patients follow the post-treatment instructions and report any adverse effects or concerns that they may have. Post-treatment instructions will be included in the forms sent to your email once a treatment has been scheduled to inform and assist you with any necessary preparations. <br /><br />

To remind you, an after-care email is issued immediately after the treatment. We make sure you're safe during and after your treatment at Dermamina London. Following our non-surgical rhinoplasty guidelines, we recommend:<br /><br />

- Do not exercise for 24 hours after receiving . <br /><br />

- For the first few days, avoid any things near the nose, including spectacles or sunglasses (avoid for 6-7 days), as well as undue pressure, touching, or rubbing of the injected areas. <br /><br />

- Dental treatments should be avoided for the first few weeks after the procedure. This involves root canal  and extraction, as well as extensive filling.<br /><br /> 

- For 24 hours, no alcohol or excessive salts.<br /><br /> 

- Wait 12 hours before putting makeup following the procedure. <br /><br />

- Avoid facials, laser treatments, and hot baths, saunas, and steam rooms.



    </AccordionItemPanel>
</AccordionItem>

</Accordion>  



<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        What if the Non-Surgical Rhinoplasty outcomes aren't to my liking? 
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Non-surgical rhinoplasty is not permanent, making it suitable for those who wish to experiment with different looks without committing to surgery. Rather than entirely modifying the nose with surgery, it allows for the restoration and enhancement of natural beauty while boosting your own collagen. <br /><br /> 

With the administration of hyaluronidase, the treatment is totally reversible. This implies the nose can be restored to its normal shape in a matter of days. Patients who appreciate the results of non-surgical rhinoplasty can have the  repeated safely over time to keep the results.<br /><br /> 

 






      



        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>  


<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Will I be given a consultation before undergoing non-surgical rhinoplasty?        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
   

        Yes, every meeting starts with a comprehensive consultation. Mina, our beautiful practitioner at Dermamina London, offers a holistic approach to , taking into account the patient's concerns and expectations, as well as providing support both before and after the procedure. <br /><br /> 

Dermamina London believes that patient support begins with a pre-treatment consultation, during which the patient is fully prepared and at ease in the hands of the practitioner, as well as an understanding of the patient's motivation and expectations.This also includes involving the patient in shared decision making and including the patient in developing individualised care-plan. <br /><br /> 

We want the patient to be as involved as they like and provide feedback throughout in order to achieve the most optimal result.



        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>  



<br /> 

<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Am I suitable for the Non-Surgical Rhinoplasty?    </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        At Dermamina, we strive for natural-looking outcomes that are personalised to each individual's needs, with the goal of enhancing rather than radically changing your appearance. <br /> <br />

Take a look at the extensive portfolio of Non-Surgical Rhinoplasty results to see how many distinct nose forms have been modified with dermal fillers' magic. If you're not sure whether the  will help you get the outcomes you want, we recommend scheduling a consultation. <br /> <br />

You can also contact a member of the team and discuss your problem, including your nose shape and the results you want to attain. It's critical to discuss your expectations with the practitioner in order to confirm whether or not they are achievable.






        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>  



<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Can I get a Non-Surgical Rhinoplasty before a Surgical Rhinoplasty?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Yes, if you're thinking about rhinoplasty but want to see how your nose might appear before making a huge choice, we recommend that you undergo nonsurgical rhinoplasty first. A non-surgical rhinoplasty can achieve many of the same goals as a surgical rhinoplasty. Make an appointment for a consultation today to learn more about what can be accomplished.<br /> <br />

If you've had a nonsurgical rhinoplasty and are ready for a surgical rhinoplasty, wait until the fillers have totally dissolved before proceeding with the procedure. If you wish the filler to dissolve, we can help by using Hyaluronidase  to dissolve it. For further information, please contact the clinic.
 





        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>  


<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Can Non-Surgical Rhinoplasty be done after Surgical Rhinoplasty?        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Yes, many people have come to us after being dissatisfied with the results of a rhinoplasty. We can help by injecting filler into the problem regions. After seven months, a nonsurgical rhinoplasty can be performed. 


        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>  



<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
     
How many non-surgical rhinoplasty treatments are required? 
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Only one session is required. Non-surgical rhinoplasty is a quick, painless procedure that takes only ten minutes and produces immediate results. After that, you'll notice a difference in your nose.



        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>  


<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Can I have other dermal filler treatments along with Non-Surgical Rhinoplasty?        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Yes, depending on the assessment and compatibility, many dermal filler treatments can be completed in one day. Did you know we have non-surgical rhinoplasty and filler packages available? With competent and honest guidance from our wonderful practitioner, you can select to add a particular amount of filler in the desired places.



        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>  




<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Non-Surgical Rhinoplasty in London? Why choose Dermamina London?       </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        When it comes to non-surgical therapies, it's critical to seek out a skilled and specialised practitioner with extensive expertise and experience in the field. An competent and specialised practitioner will know exactly what to do to achieve the desired results while reducing risks, side effects, and difficulties. <br /> <br />

Dermamina London is known for producing outcomes that are both natural-looking and life-changing. We are dedicated to providing clinically sophisticated, evidence-based cosmetic treatments with the highest level of safety and honesty; we will not recommend any procedures if they are not appropriate for you. <br /> <br />

Check out our fantastic reviews given by our beloved patients, as well as our extensive portfolio of results all completed by our highly experienced, medically qualified, and most compassionate practitioner Mina.







        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion> 

          </Col>




        </Row>



<br /> <br />
              <a href 
                                            ="https://connect.pabau.com/bookings.php?compid=8607" 
                                            activeClassName="active"
                                           
                                          
                                        >      <Bookbutton>
                
                
                Book Appointment Online
                
                
                </Bookbutton> </a>
        


       


        </Container>
        </Section>

 


    );
}